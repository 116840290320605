html.manager {
	width: 100vw;
	height: 100%;
}
.manager body {
	width: 100%;
	height: 100%;
	position: relative;
}

/* same spacing */
aside, main, nav {
	padding: var(--em6);
}
main {
	gap: var(--em6);
}
.menu {
	position: absolute;
	height: var(--em5);
	margin: var(--em6);
}

/*  */
main {
  display: flex;
  flex-direction: column;
}

/* MEDIA QUERIES */
/* mobile */
@media screen and (min-width: 410px) and (min-height: 730px) and (orientation: portrait) {
	/* same spacing */
	aside, main {
		padding: var(--em7) var(--em11);
	}
	nav {
		padding: var(--em7);
	}

	.menu {
		margin: var(--em7) var(--em11);
	}
}

/* tablet */
@media screen and (min-width: 480px) /* and (min-height: 800px) */ and (orientation: portrait) {
	/* same spacing */
	aside, main {
		padding: var(--em7) var(--em13);
	}
	main {
		gap: var(--em7);
	}
	.menu {
		height: var(--em5-6);
		margin: var(--em7) var(--em13);
	}
}
@media screen and (min-width: 600px) /* and (min-height: 960px) */ and (orientation: portrait) {
	/* same spacing */
	aside, main {
		padding: var(--em7) var(--em15);
	}
	main {
		gap: var(--em7);
	}
	.menu {
		margin: var(--em7) var(--em15);
	}
}
@media screen and (min-width: 768px) and (orientation: portrait) {
	aside, main {
		padding: var(--em7) var(--em17);
	}
	.menu {
		margin: var(--em7) var(--em17);
		height: var(--em6-7);
	}
}


/* tablet landscape */
@media screen and (orientation: landscape) {
	.manager body,
	.manager #root,
	.manager #App,
	.manager main,
	.manager menu {
		height: 100%;
	}
	/* main {
		overflow: overlay;
	} */
}
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	aside {
		padding: 0;
	}
	/* same spacing */
	main {
		padding: var(--em4);
		width: auto;
		width: 100%;
		gap: var(--em4);
	}
	.menu {
		margin: var(--em7) var(--em19);
	}
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
	main {
		gap: var(--em6);
		padding: var(--em6);
	}
}

/* landscape */
@media screen and (min-width: 1280px) and (max-height: 799px) and (orientation: landscape) {
	main {
		gap: var(--em6);
		padding: var(--em6);
	}
	menu {
		gap: var(--em6);
	}
}
@media screen and (min-width: 1920px) and (min-height: 929px) {
	main {
		gap: var(--em10);
		padding: var(--em10);
	}
}