aside {
	/* position: absolute; */
	position: fixed;
	background: transparent;
	width: 100vw;
	height: 100%;
	z-index: 9999;
	top: 0;
	left: -100%;
	transition: var(--t375);
}
aside.open {
	left: 0;
}
nav {
	overflow: scroll;
	position: absolute;
	left: 0;
	top: 0;
	width: 75%;
	height: 100%;
	background: var(--white);
	border-radius: 0 var(--em2) var(--em2) 0;
	box-shadow: 0 0 var(--em2) var(--dark375);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--em10);
}
aside figure {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--em1);
}
aside img {
	border-radius: 50%;
	border: 2px solid var(--red);
	padding: 3px;
	width: var(--em14);
	height: var(--em14);
	object-fit: cover;
	object-position: top;
}
aside h4 {
	font-size: var(--em3-4);
	font-weight: var(--fw400);
	cursor: default;
}
.rank {
	display: flex;
	justify-content: center;
	gap: var(--em1);
}
.rank svg {
	height: var(--em4-5);
}
aside figure svg,
aside ul svg {
	height: var(--em4-5);
}
/*  */
aside .logo {
	display: none;
}
@media screen and (min-height: 560px) {
	aside .logo {
		display: block;
	}
	nav {
		overflow: visible;
	}
}
/*  */
aside ul {
	display: flex;
	flex-direction: column;
	gap: var(--em0);
	width: 87.5%;
}
aside ul li {
	display: flex;
	align-items: center;
	gap: var(--em2);
	cursor: pointer;
	user-select: none;
	background: transparent;
	padding: var(--em1) var(--em2);
	border-radius: var(--em0-1);
	transition: var(--t375);
}
aside ul li p,
aside ul li svg,
aside ul li svg path,
aside ul li svg circle,
aside ul li svg rect {
	transition: var(--t375);
}
aside ul li:hover p {
	color: var(--red);
}
aside ul li:hover svg path,
aside ul li:hover svg circle {
	fill: var(--red);
}
aside ul hr {
	border: none;
	border-top: 2px solid var(--dark);
	margin: var(--em0) 0;
}
aside figure:last-child svg {
	position: absolute;
	bottom: 1em;
	height: var(--em8);
}
aside figure:last-child svg path {
	fill: var(--red);
}
aside ul li svg path,
aside ul li svg circle,
aside ul li svg rect {
	fill: var(--dark);
}
/* active menu */
aside ul li.active {
	background: var(--dark125);
	background: var(--red);
}
aside ul li.active p {
	color: var(--red);
	color: var(--white);
}
aside ul li.active svg circle,
aside ul li.active svg path {
	fill: var(--red);
	fill: var(--white);
}

/* MEDIA QUERIES */
/* mobile */
@media screen and (min-width: 375px) and (min-height: 667px) and (orientation: portrait) {
	nav {
		width: 62.5%;
	}
	aside figure {
		gap: var(--em2);
	}
	aside img {
		width: var(--em15);
		height: var(--em15);
	}
	aside ul {
		gap: var(--em1);
	}
}
@media screen and (min-width: 410px) /* and (min-height: 730px) */ and (orientation: portrait) {
	nav {
		width: 62.5%;
	}
	aside figure:last-child svg {
		height: var(--em8-9);
	}
}

/* tablet */
@media screen and (min-width: 480px) /* and (min-height: 800px) */ and (orientation: portrait) {
	nav {
		gap: var(--em12);
	}
	aside {
		transition: var(--t500);
	}
	aside h4 {
		font-size: var(--em4);
	}
	aside ul {
		width: 75%;
		gap: var(--em1-2);
	}
	aside ul li p {
		font-size: var(--em3-4);
	}
	.rank svg {
		height: var(--em5);
	}
	aside figure svg,
	aside ul svg {
		height: var(--em5);
	}
	aside figure:last-child svg {
		height: var(--em10);
	}
}
@media screen and (min-width: 600px) /* and (min-height: 960px) */ and (orientation: portrait) {
	nav {
		width: 50%;
	}
	aside figure {
		margin-top: var(--em1);
	}
}
@media screen and (min-width: 768px) /* and (min-height: 954px) */ and (orientation: portrait) {
	nav {
		width: 37.5%;
	}
	aside ul {
		gap: var(--em2-3);
	}
	aside ul hr {
		margin: var(--em0-1) 0;
	}
	aside ul li {
		padding: var(--em1-2) var(--em3);
	}
	.rank {
		gap: var(--em2);
	}
	.rank svg {
		height: var(--em5-6);
	}
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	.menu {
		display: none;
	}
	nav {
		gap: var(--em8);
	}
	aside {
		position: relative;
		width: auto;
		left: 0;
	}
	aside nav {
		position: relative;
		width: 100%;
		padding: var(--em3) var(--em5);
		box-shadow: 0 0 var(--em2) var(--dark25);
	}
	aside img {
		width: var(--em13);
		height: var(--em13);
	}
	aside h4 {
		font-size: var(--em3);
		white-space: nowrap;
	}
	.rank svg {
		height: var(--em4);
	}
	aside ul {
		width: 100%;
		gap: var(--em2-3);
		gap: var(--em0);
	}
	aside ul li {
		padding: var(--em0-1) var(--em1);
	}
	aside ul li p {
		font-size: var(--em2-3);
	}
	aside ul svg {
		height: var(--em4);
	}
	aside figure:last-child svg {
		height: var(--em7);
	}
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
	aside nav {
		padding: var(--em5) var(--em8);
	}
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
	aside nav {
		padding: var(--em7) var(--em11);
	}
	aside figure {
		gap: var(--em2);
	}
	aside img {
		width: var(--em14);
		height: var(--em14);
	}
	aside h4 {
		font-size: var(--em4);
	}
	.rank svg {
		height: var(--em4-5);
	}
	aside ul {
		gap: var(--em1);
	}
	aside ul li p {
		font-size: var(--em3);
	}
	aside ul li svg {
		height: var(--em4-5);
	}
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
	nav {
		gap: var(--em11);
	}
	aside figure:last-child svg {
		bottom: var(--em4);
		height: var(--em9);
	}
	aside ul {
		gap: var(--em2);
	}
	aside ul li svg {
		height: var(--em4-5);
	}
	aside ul li p {
		font-size: var(--em3);
	}
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
	nav {
		gap: var(--em12);
	}
	aside nav {
		padding: var(--em8) var(--em11-12);
	}
	.rank svg {
		height: var(--em5);
	}
}

/* landscape */
@media screen and (min-width: 1280px) and (max-height: 799px) {
	aside nav {
		padding: var(--em6) var(--em10);
	}
	aside figure:last-child svg {
		height: var(--em8);
	}
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
	nav {
		gap: var(--em12);
	}
	aside nav {
		padding: var(--em6) var(--em11-12);
	}
	aside ul {
		gap: var(--em2);
	}
	aside ul li svg {
		height: var(--em5);
	}
}
@media screen and (min-width: 1536px) {
	/* 700 */
	aside ul {
		gap: var(--em1);
	}
	aside ul li svg {
		height: var(--em5);
	}
	aside figure:last-child svg {
		bottom: var(--em5);
		height: var(--em9);
	}
	aside nav {
		padding: var(--em6) var(--em11-12);
	}
	aside img {
		width: var(--em14-15);
		height: var(--em14-15);
	}
	aside ul hr {
		margin: var(--em0) 0;
	}
}
@media screen and (min-width: 1920px) {
	/* 929 */
	aside {
		width: auto;
	}
	aside nav {
		padding: var(--em8) var(--em14);
	}
	aside figure:last-child svg {
		height: var(--em11);
	}
	.rank svg {
		height: var(--em5-6);
	}
	aside h4 {
		font-size: var(--em4-5);
	}
}
