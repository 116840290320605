.login body {
	overflow: hidden;
}
.login .main-div {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: white;
}
form {
	margin-top: var(--em10);
	display: flex;
	flex-direction: column;
	gap: var(--em4);
}
.figure {
	display: flex;
	gap: var(--em4);
}
.figure a {
	display: flex;
}
.figure svg {
	height: var(--em3);
	height: var(--em3-4);
}
.figure svg path,
.figure svg rect,
.figure svg circle {
	fill: var(--dark);
	transition: var(--t250);
}
.figure svg:hover path,
.figure svg:hover rect,
.figure svg:hover circle {
	fill: var(--red);
}
/* titulo */
.login h1 * {
	cursor: default;
}
.login h1 span {
	font-size: var(--em5-6);
	font-weight: var(--fw600);
}
.login h1 span:first-child {
	color: var(--dark);
	font-size: var(--em8);
}
.login h1 span:not(:first-child) {
	line-height: 20px;
}
.login h1 span:last-child {
	color: var(--red);
}
.login .section {
	display: flex;
	flex-direction: column;
	gap: var(--em0);
	width: 100%;
}
.login input {
	width: 100%;
	border: none;
	border: 2px solid var(--dark);
	padding: var(--em0-1) var(--em1);
	border-radius: var(--em1);
	font-size: var(--em2-3);
	transition: var(--t375);
}
.login .input-div {
	position: relative;
}
.login .eye {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: var(--em1);
	height: 62.5%;
	cursor: pointer;
}
.login button {
	margin: var(--em2) 0;
	background: var(--red);
	border: none;
	border: 2px solid var(--red);
	color: var(--white);
	width: fit-content;
	align-self: flex-end;
	padding: var(--em0-1) var(--em1);
	border-radius: var(--em1-2);
	cursor: pointer;
	user-select: none;
	transition: var(--t250);
}
.login button:hover {
	background: transparent;
	color: var(--red);
}
.login label,
.login button,
.recovery {
	font-weight: var(--fw500);
}
.login label {
	transition: var(--t375);
}
.login .section:focus-within label {
	color: var(--red);
}
.login .section input:focus {
	border: 2px solid var(--red);
	outline: 0;
}
.recovery span {
	font-size: var(--em2-3);
}
.recovery span:last-child {
	color: var(--red);
	cursor: pointer;
	transition: var(--t250);
	text-decoration: underline solid transparent;
	text-underline-offset: 1.5px;
}
.recovery:hover span:last-child {
	text-decoration: underline solid var(--red);
}
.recovery {
	cursor: default;
}
/* LOGO */
.login .logo {
	position: absolute;
	height: var(--em9);
	bottom: var(--em4);
	cursor: pointer;
}

/* remove input BG on autocomplete */
.login input:-webkit-autofill,
.login input:-webkit-autofill:hover,
.login input:-webkit-autofill:focus,
.login textarea:-webkit-autofill,
.login textarea:-webkit-autofill:hover,
.login textarea:-webkit-autofill:focus,
.login select:-webkit-autofill,
.login select:-webkit-autofill:hover,
.login select:-webkit-autofill:focus {
	box-shadow: 0 0 0px 1000px #fff inset !important;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

/* MEDIA QUERIES */
@media screen and (max-height: 567px) {
	.login .logo {
		display: none;
	}
}
/* mobile */
@media screen and (min-width: 375px) and (min-height: 667px) and (orientation: portrait) {
	form {
		margin-top: var(--em12);
		gap: var(--em5);
		width: 62.5%;
	}
	.figure {
		gap: var(--em4-5);
	}
	.figure svg {
		height: var(--em4);
	}
	/* titulo */
	.login h1 span {
		font-size: var(--em6);
	}
	.login h1 span:first-child {
		font-size: var(--em8-9);
	}
}
@media screen and (min-width: 410px) and (min-height: 730px) and (orientation: portrait) {
	form {
		gap: var(--em6);
	}
	.login input {
		padding: var(--em1) var(--em1);
	}
	.login .section {
		gap: var(--em0-1);
	}
	.login .logo {
		height: var(--em11);
	}
}

/* tablet */
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
	.login .section label br {
		display: none;
	}
	.login .main-div {
		background: var(--dark125);
		justify-content: center;
	}
	.login form {
		background: var(--white);
		width: 75%;
		padding: var(--em6) var(--em10);
		border-radius: var(--em2);
		box-shadow: 0 0 var(--em2) var(--dark25);
		margin-top: 0;
		gap: var(--em4);
	}
	.login .logo {
		top: var(--em8);
		left: var(--em12);
		bottom: auto;
		height: var(--em10);
	}
}
@media screen and (min-width: 600px) and (min-height: 960px) and (orientation: portrait) {
	.login form {
		width: 62.5%;
	}
}
@media screen and (min-width: 768px) and (min-height: 954px) and (orientation: portrait) {
	.login form {
		width: calc(50% + ((62.5% - 50%) / 2));
	}
}
@media screen and (min-width: 912px) and (min-height: 1360px) and (orientation: portrait) {
	.login form {
		width: 50%;
	}
}
@media screen and (min-width: 1024px) and (min-height: 1360px) and (orientation: portrait) {
	.login form {
		width: calc(37.5% + ((50% - 37.5%) / 2));
	}
}

/* table landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	.login .section label br {
		display: none;
	}
	.login .logo {
		top: auto;
		left: auto;
		right: var(--em6);
		bottom: var(--em4);
		height: var(--em10);
	}
	.login .main-div {
		background: var(--dark125);
		justify-content: center;
		align-items: flex-start;
	}
	.login form {
		background: var(--white);
		width: 37.5%;
		padding: var(--em6) var(--em10);
		border-radius: var(--em2);
		box-shadow: 0 0 var(--em2) var(--dark25);
		margin-top: 0;
		margin-left: var(--em14);
		gap: var(--em2);
	}
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
	.login .logo {
		top: auto;
		left: auto;
		right: var(--em6);
		bottom: var(--em4);
		height: var(--em10);
	}
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
	.login form {
		gap: var(--em3);
	}
	.login .logo {
		top: var(--em10);
		left: var(--em11);
		right: auto;
		bottom: auto;
		height: var(--em11);
	}
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
	.login form {
		width: calc(25% + ((37.5% - 25%) / 2));
		margin-left: var(--em16);
	}
	.login .logo {
		top: var(--em10);
		left: var(--em11);
		right: auto;
		bottom: auto;
		height: var(--em11);
	}
}

/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
	.login .logo {
		top: var(--em8);
		left: var(--em10);
		right: auto;
		bottom: auto;
		height: var(--em10);
	}
	.login form {
		width: calc(25% + ((37.5% - 25%) / 2));
		margin-left: var(--em16);
	}
}
@media screen and (min-width: 1280px) and (max-height: 799px) {
	.login .logo {
		height: var(--em8);
		top: var(--em5);
		left: var(--em8);
	}
}
@media screen and (min-width: 1360px) and (max-height: 1023px) {
	.login .logo {
		top: var(--em7);
		left: var(--em10);
		height: var(--em9);
	}
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
	.login .logo {
		height: var(--em11);
	}
	.login form {
		gap: var(--em3);
	}
}
@media screen and (min-width: 1536px) { /* 700 */
	/* .login .logo path {
		fill: var(--white);
		fill: var(--dark);
		fill: var(--red);
	} */
	/* .login .main-div {
		background-image: url('../../public/building.jpg');
		background-repeat: no-repeat;
  	background-size: cover;
		background-position: left center;
	} */
	.login .logo {
		height: var(--em11);
	}
	.login form {
		width: 25%;
		width: 27.5%;
		gap: var(--em4);
	}
}
@media screen and (min-width: 1600px) { /* 749 */
	.login .logo {
		top: var(--em10);
		left: var(--em12);
	}
	.login form {
		gap: var(--em4);
		width: 25%;
	}
}
@media screen and (min-width: 2560px) { /* 929 */
	.login .logo {
		top: var(--em12);
		left: var(--em16);
		height: var(--em11-12);
	}
	.login form {
		gap: var(--em6);
		margin-left: var(--em19);
		width: calc(12.5% + ((25% - 12.5%) / 2));
	}
	.login h1 span {
		font-size: var(--em7);
	}
	.login h1 span:first-child {
		font-size: var(--em10);
	}
	.figure {
		gap: var(--em6);
	}
	.figure svg {
		height: var(--em4);
	}
	.login label,
	.login button,
	.recovery {
		font-size: var(--em3-4);
	}
}

/* error */
.login .error {
	position: absolute;
	background: var(--red);
	color: var(--white);
	width: 100%;
	left: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: var(--em3) var(--em3) 0 0;
	transition: var(--t500);
	padding: 7.5% 5%;
	bottom: -20%;
}
.login .error.status {
	bottom: 0%;
}

/* tablet */
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
	.login .error {
		padding: 2.5% 10%;
		bottom: -8.75%;
	}
}
/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	.login .error {
		padding: 1.25% 10%;
		bottom: -10%;
	}
}
/* landscape */
@media screen and (min-width: 1536px) { /* 700 */
	.login .error {
		padding: 0.625% 10%;
		bottom: -10%;
	}
}
