menu.recibos {
  display: flex;
  flex-direction: column;
}
menu.recibos section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: var(--em5);
}
menu.recibos h4 {
  font-size: var(--em2-3);
}
menu.recibos section article {
  padding: var(--em2);
  cursor: pointer;
}

/* MEDIA QUERIES */
/* mobile */
@media screen and (min-width: 375px) and (min-height: 667px) and (orientation: portrait) {
  menu.recibos section {
    gap: var(--em6);
  }
  menu.recibos section article {
    padding: var(--em3);
  }
}
@media screen and (min-width: 410px) and (min-height: 730px) and (orientation: portrait) {
  menu.recibos {
		gap: var(--em7);
	}
  menu.recibos section {
    gap: var(--em7);
  }
}

/* tablet */
@media screen and (min-width: 480px) /* and (min-height: 800px) */ and (orientation: portrait) {
  menu.recibos section {
    grid-template-columns: repeat(2, 1fr);
  }
  menu.recibos h4 {
    font-size: var(--em3);
  }
}
@media screen and (min-width: 600px) /* and (min-height: 960px) */ and (orientation: portrait) {
  menu.recibos {
    grid-template-columns: 1fr;
  }
  menu.recibos section {
    grid-template-columns: repeat(auto-fill, minmax(125px, auto));
  }
}
@media screen and (min-width: 768px) and (orientation: portrait) {
  menu.recibos section {
    grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  }
}
@media screen and (min-width: 1024px) and (orientation: portrait) {
  menu.recibos section {
    grid-template-columns: repeat(auto-fill, minmax(200px, auto));
  }
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
  menu.recibos {
    grid-template-columns: repeat(3, auto); /* <- ? */
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, auto);
    display: flex;
    flex-direction: column;
  }
  menu.recibos section {
    grid-template-columns: repeat(4, 1fr);
  }
  menu.recibos main { /* menu main só ?, no manager.css */
    width: 100%;
  }
  menu.recibos section article {
    gap: var(--em3);
  }
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
  menu.recibos {
    grid-template-rows: repeat(4, 1fr);
  }
  menu.recibos section {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
  menu.recibos h4 {
    font-size: var(--em3);
  }
  menu.recibos section {
    grid-template-columns: repeat(5, 1fr);
  }
  menu.recibos section article {
    padding: var(--em3);
  }
  menu.recibos .pdf {
    height: var(--em10);
  }
  .PDFinfo p {
    font-size: var(--em2-3);
  }
}

/* landscape */
@media screen and (min-width: 1280px) and (max-height: 799px) {
  menu.recibos section {
    grid-template-columns: repeat(5, 1fr);
    gap: var(--em6);
  }
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
  menu.recibos section {
    grid-template-columns: repeat(5, 1fr);
    gap: var(--em7);
  }
  menu.recibos h4 {
    font-size: var(--em3);
  }
}
@media screen and (min-width: 1536px) { /* 700 */
  menu.recibos section {
    grid-template-columns: repeat(5, 1fr);
    gap: var(--em7);
  }
  menu.recibos section article {
    gap: var(--em2);
    padding: var(--em2-3);
  }
  menu.recibos article h4 {
    font-size: var(--em3);
  }
  menu.recibos .pdf {
    height: var(--em10);
  }
  menu.recibos .PDFinfo p {
    font-size: var(--em2-3);
  }
  menu.recibos section {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (min-width: 1920px) { /* 929 */
  menu.recibos section {
    grid-template-columns: repeat(6, 1fr);
    gap: var(--em11);
  }
  menu.recibos article h4 {
    font-size: var(--em3-4);
  }
  menu.recibos section article {
    gap: var(--em3);
  }
  menu.recibos article span p {
    font-size: var(--em3);
  }
  menu.recibos article span svg {
    height: var(--em3);
  }
}