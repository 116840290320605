menu.protocolos {
  display: flex;
  flex-direction: column;
}
menu.protocolos article {
  cursor: default;
}
menu.protocolos article.slider figure,
menu.protocolos button {
  cursor: pointer;
}
menu.protocolos {
  position: relative;
  grid-template-rows: repeat(3, auto);
  gap: var(--em4);
}
menu.protocolos article.aviso,
menu.protocolos article.slider {
  gap: var(--em4);
  flex-direction: row;
  justify-content: flex-start;
  background: transparent;
  /* background: rgba(255, 99, 71, 0.25); */
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
menu.protocolos article.slider {
  overflow-x: overlay;
}

menu.protocolos figure {
  justify-content: center;
  align-items: center;
  background: var(--white);
  padding: 0 var(--em1);
  border-radius: var(--em1);
  border: 2px solid transparent;
  transition: var(--t375);
}
menu.protocolos figure.selected {
  border: 2px solid var(--red);
}
menu.protocolos figure svg {
  height: var(--em12);
}
menu.protocolos ::-webkit-scrollbar {
  display: none;
}
menu.protocolos .mask {
  position: absolute;
  width: 15%;
  z-index: 1;
  pointer-events: none;
  user-select: none;
  transition: var(--t250);
  top: 0;
}
menu.protocolos .mask.left {
  left: -2px;
  margin-right: -2px;
  background: linear-gradient(to right, var(--dark125), transparent);
}
menu.protocolos .mask.right {
  right: -2px;
  background: linear-gradient(to left, var(--dark125), transparent);
}
menu.protocolos .mask.inv {
  opacity: 0;
}
/* text */
menu.protocolos article.aviso {
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: var(--em2);
}
.aviso p,
menu.protocolos article:last-child h6,
menu.protocolos article:last-child p,
menu.protocolos article:last-child li {
  font-weight: var(--fw400);
  font-size: .875em;
}
menu.protocolos .txt .p-footer {
  font-size: .75em;
}
menu.protocolos article:last-child u {
  font-weight: var(--fw500);
  font-size: 1em;
}
menu.protocolos article {
  gap: var(--em2);
}
menu.protocolos h6 {
  text-align: center;
}
menu.protocolos ul {
  gap: var(--em1);
}
menu.protocolos li {
  flex-direction: row;
}
menu.protocolos li::before {
  content: '•';
  padding-right: var(--em0);
  font-size: var(--em4);
  display: flex;
  align-items: flex-start;
}
menu.protocolos button {
  border: none;
  width: fit-content;
  margin: 0 auto;
  padding: var(--em0-1) var(--em2);
  border-radius: var(--em0-1);
  color: var(--white);
}

/* MEDIA */
/* tablet */
@media screen and (min-width: 480px) /* and (min-height: 800px) */ and (orientation: portrait) {
  menu.protocolos figure svg {
    height: var(--em13);
  }
  menu.protocolos article.aviso,
  menu.protocolos article.slider {
    gap: var(--em6);
  }
  menu.protocolos article {
    gap: var(--em4);
  }
  menu.protocolos .mask {
    width: 20%;
  }
}
@media screen and (min-width: 600px) /* and (min-height: 960px) */ and (orientation: portrait) {
  menu.protocolos {
    gap: var(--em6);
  }
  .aviso p,
  menu.protocolos article:last-child h6,
  menu.protocolos article:last-child p,
  menu.protocolos article:last-child li {
    font-weight: var(--fw400);
    font-size: 1em;
  }
  menu.protocolos article:last-child h4 {
    font-size: 1.25em;
  }
  menu.protocolos .txt .p-footer {
    font-size: .875em;
  }
  menu.protocolos button {
    padding: var(--em1-2) var(--em2-3);
  }
}
@media screen and (min-width: 768px) /* and (min-height: 954px) */ and (orientation: portrait) {
  menu.protocolos article {
    padding: var(--em4) var(--em6);
    gap: var(--em6);
  }
  menu.protocolos article.aviso br {
    display: none;
  }
  menu.protocolos article.aviso {
    padding: 0 var(--em10);
  }
}
@media screen and (min-width: 800px) /* and (min-height: 1280px) */ and (orientation: portrait) {
  menu.protocolos article {
    padding: var(--em6) var(--em8);
    gap: var(--em6);
  }
  menu.protocolos article.aviso {
    padding: 0 var(--em12);
  }
}
@media screen and (min-width: 800px) /* and (min-height: 1280px) */ and (orientation: portrait) {
  menu.protocolos article.aviso {
    padding: 0 var(--em15);
  }
}
@media screen and (min-width: 912px) /* and (min-height: 1360px) */ and (orientation: portrait) {
  menu.protocolos .mask {
    display: none;
  }
  menu.protocolos article.aviso {
    padding: 0;
    width: 75%;
  }
  menu.protocolos article.aviso p {
    text-align: start;
  }
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
  menu.protocolos ::-webkit-scrollbar {
    display: block;
  }
  menu.protocolos .mask,
  menu.protocolos article.aviso br {
    display: none;
  }
  menu.protocolos {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
    gap: var(--em2);
  }
  menu.protocolos article {
    padding: var(--em2) var(--em4);
  }
  menu.protocolos article.aviso {
    padding: 0 15%;
  }
  /* menu.protocolos figure svg {
    height: 100%;
  } */
  menu.protocolos article.slider {
    gap: var(--em5);
    overflow-x: overlay;
  }
  menu.protocolos .slider {
    grid-row: 1 / 2;
  }
  menu.protocolos .aviso {
    grid-row: 2 / 3;
  }
  menu.protocolos .txt {
    grid-row: 3 / 6;
    overflow-y: overlay;
    height: fit-content;
    max-height: 100%;
  }
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
  menu.protocolos {
    grid-template-rows: repeat(6, 1fr);
    gap: var(--em2);
  }
  menu.protocolos .txt {
    grid-row: 3 / 7;
  }
  menu.protocolos article {
    padding: var(--em4) var(--em6);
    gap: var(--em4);
  }
  menu.protocolos article.aviso {
    padding: 0 20%;
  }
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
  menu.protocolos {
    gap: var(--em2);
    grid-template-rows: repeat(8, 1fr);
  }
  menu.protocolos article {
    padding: var(--em5) var(--em6);
  }
  menu.protocolos article.txt {
    grid-row: 3 / 9;
  }
  menu.protocolos h4 {
    font-size: var(--em3-4);
  }
  menu.protocolos button {
    padding: var(--em1-2) var(--em3);
  }
  menu.protocolos article:last-child h6 {
    font-size: var(--em3);
  }
  menu.protocolos article.aviso {
    padding: 0;
    justify-content: flex-start;
  }
}


/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
  menu.protocolos {
    display: flex;
    flex-direction: column;
    gap: var(--em6);
  }
  menu.protocolos figure svg {
    height: var(--em12);
  }
  menu.protocolos article.aviso {
    padding: 0;
    justify-content: flex-start;
  }
  menu.protocolos h6 {
    text-align: start;
  }
}
@media screen and (min-width: 1360px) and (max-height: 910px) {
  menu.protocolos figure svg {
    height: var(--em12-13);
  }
  menu.protocolos article.slider {
    gap: var(--em6);
  }
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
  menu.protocolos {
    gap: var(--em8);
  }
}
@media screen and (min-width: 1536px) { /* 700 */
  menu.protocolos figure svg {
    height: var(--em13);
  }
  menu.protocolos article.slider {
    gap: var(--em7);
  }
  menu.protocolos h4 {
    font-size: var(--em4);
  }
  menu.protocolos article:last-child h6 {
    font-size: var(--em3);
  }
  menu.protocolos button {
    padding: var(--em2) var(--em3-4);
  }
  menu.protocolos article.txt {
    width: 62.5%;
  }
}
@media screen and (min-width: 1920px) { /* 929 */
  menu.protocolos {
    display: flex;
    flex-direction: column;
    grid-template-rows: none;
  }
  menu.protocolos article {
    gap: var(--em6);
  }
  menu.protocolos figure svg {
    height: var(--em13-14);
  }
  menu.protocolos article.txt {
    width: 50%;
  }
  menu.protocolos h6 {
    text-align: start;
  }
}