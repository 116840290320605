/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap'); */
* {
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 0;
	text-decoration: none;
	font-family: "Rubik", sans-serif;
	font-size: 16px;
}
body {
	color: var(--dark);
	background: var(--dark125);
	width: 100%;
}

:root {
	/* colors */
	--red: #c73218;
	--darkred: #a53522;
	--white: #ffffff;
	--dark: #404040;
	--darktransparent: rgb(64, 64, 64, 0);
	--dark125: #e7e7e7;
	--dark25: #cfcfcf;
	--dark375: #b7b7b7;
	--dark50: #9f9f9f;
	--dark625: #888888;
	--dark75: #707070;
	--dark875: #585858;
	--pdf: #ff1631;
	--word: #2a5699;
	--excel: #207245;

	/* font weight */
	--fw300: 300;
	--fw400: 400;
	--fw500: 500;
	--fw600: 600;
	--fw700: 700;

	/* transitions */
	--t125: all 125ms ease-in-out;
	--t250: all 250ms ease-in-out;
	--t375: all 375ms ease-in-out;
	--t500: all 500ms ease-in-out;

	/* % */
	--pbr: 1.25%;
	--p0: 2.5%;
	--p0-1: 3.75%;
	--p1: 5%;
	--p2: 7.5%;
	--p3: 10%;
	--p4: 12.5%;
	--p5: 15%;
	--p6: 17.5%;
	--p7: 20%;
	--p8: 22.5%;
	--p9: 25%;
	--p10: 27.5%;
	--p11: 30%;
	--p12: 32.5%;
	--p13: 35%;
	--p14: 37.5%;
	--p15: 40%;

	/* em */
	--embr: 0.125em;
	--em0: 0.25em;
	--em0-1: 0.375em;
	--em1: 0.5em;
	--em1-2: 0.625em;
	--em2: 0.75em;
	--em2-3: 0.875em;
	--em3: 1em;
	--em3-4: 1.125em;
	--em4: 1.25em;
	--em4-5: 1.375em;
	--em5: 1.5em;
	--em5-6: 1.625em;
	--em6: 1.75em;
	--em6-7: 1.875em;
	--em7: 2em;
	--em8: 2.25em;
	--em8-9: 2.375em;
	--em9: 2.5em;
	--em9-10: 2.625em;
	--em10: 2.75em;
	--em11: 3em;
	--em11-12: 3.5em;
	--em12: 4em;
	--em12-13: 4.5em;
	--em13: 5em;
	--em13-14: 5.5em;
	--em14: 6em;
	--em14-15: 6.5em;
	--em15: 7em;
	--em16: 8em;
	--em17: 9em;
	--em18: 10em;
	--em18-19: 12.5em;
	--em19: 15em;
	--em20: 20em;
}
