/* firstTime */
.login.init blockquote {
	position: absolute;
	bottom: 12.5%;
	left: 20%;
}
.login.init blockquote svg {
	height: var(--em11-12);
}
.login.init blockquote svg path {
	fill: var(--dark);
}
@media screen and (max-height: 567px) {
	.login.init blockquote {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}
}


/* MEDIA QUERIES */
/* tablet */
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 15%;
		left: 15%;
	}
}
@media screen and (min-width: 600px) and (min-height: 960px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 21.25%;
		left: 22.5%;
	}
}
@media screen and (min-width: 768px) and (min-height: 1024px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 22.5%;
		left: 23.75%;
	}
}
@media screen and (min-width: 768px) and (min-height: 1024px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 27.5%;
		left: 22.5%;
	}
}
@media screen and (min-width: 912px) and (min-height: 1360px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 28.75%;
		left: 25%;
	}
}
@media screen and (min-width: 1024px) and (min-height: 1360px) and (orientation: portrait) {
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 28.75%;
		left: 28.75%;
	}
}

/* table landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	.login.init blockquote svg {
		height: var(--em12);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 0;
		left: 17.5%;
	}
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 6.25%;
		left: 10%;
	}
}
@media screen and (min-width: 1024px) and (min-height: 768px) and (orientation: landscape) {
	.login.init blockquote {
		position: absolute;
		bottom: 15%;
		left: 10%;
	}
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
	.login.init blockquote {
		position: absolute;
		bottom: 16.25%;
		left: 10%;
	}
}
@media screen and (min-width: 1360px) and (min-height: 912px) and (orientation: landscape) {
	.login.init blockquote {
		position: absolute;
		bottom: 21.25%;
		left: 10%;
	}
}

/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
	.login.init blockquote svg {
		height: var(--em12);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 10%;
		left: 13.75%;
	}
}
@media screen and (min-width: 1280px) and (max-height: 799px) {
	.login.init blockquote {
		position: absolute;
		bottom: 7.5%;
		left: 11.25%;
	}
}
@media screen and (min-width: 1360px) and (max-height: 1023px) {
	.login.init blockquote {
		position: absolute;
		bottom: 10%;
		left: 11.25%;
	}
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 15%;
		left: 10%;
	}
}
@media screen and (min-width: 1536px) { /* 700 */
	.login.init blockquote svg {
		height: var(--em12-13);
	}
	.login.init blockquote {
		position: absolute;
		bottom: 11.25%;
		left: 10%;
	}
}
@media screen and (min-width: 1600px) { /* 749 */
	.login.init blockquote {
		bottom: 12.5%;
		left: 8.75%;
	}
}
@media screen and (min-width: 1920px) { /* 929 */
	.login.init blockquote {
		bottom: 20%;
		left: 7.5%;
	}
}
@media screen and (min-width: 2560px) { /* 929 */
	.login.init blockquote {
		bottom: 16.25%;
		left: 10%;
	}
}
@media screen and (min-width: 2560px) and (min-height: 1280px) {
	.login.init blockquote {
		bottom: 26.25%;
	}
}