/* No Scroll */
/* body.noScroll {
	overflow: hidden;
} */

/* # */
.none {
	display: none;
}

/* OPACITY */
.fade {
	opacity: 0;
}
.darken {
  opacity: .5;
}
.darken25 {
  opacity: .25;
}

/* UNCLICKABLE */
.pointer-events {
	pointer-events: none;
	user-select: none;
	z-index: -9999;
	cursor: default;
}

/* USER-SELECT */
.userSelect {
	user-select: none;
}

/* GAP: 0 */
.gap-zero {
  gap: var(--em0);
}

/* Cursors */
.cursor-defaut {
	cursor: default;
}
.cursor-pointer {
	cursor: pointer;
}

@media screen and (min-height: 520px) {
	/* No Scroll */
	body.noScroll {
		overflow: hidden;
	}
}
/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	#App {
		display: flex;
	}
}

/* SCROLLBARS */
::-webkit-scrollbar {
	width: var(--em4);
}
::-webkit-scrollbar-thumb {
	background: var(--red);
	border: 6px solid transparent;
	border-radius: var(--em3);
  height: var(--p7-8);
	background-clip: padding-box;
}