header {
  display: flex;
  justify-content: space-between;
}
header figure {
  display: flex;
  gap: var(--em9);
}
header figure svg {
  height: var(--em5);
  cursor: pointer;
}
header figure svg path {
  transition: var(--t250);
}
header figure .logout:hover path {
  fill: var(--red);
}
header figure svg path {
  fill: var(--dark);
}

/* tablet */
@media screen and (min-width: 480px) /* and (min-height: 800px) */ and (orientation: portrait) {
  header figure svg {
    height: var(--em5-6);
  }
}
@media screen and (min-width: 480px) and (orientation: portrait) {
  header figure svg {
    height: var(--em6-7);
  }
}

/* tablet landscape */
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
  header figure svg {
    height: var(--em5-6);
  }
}