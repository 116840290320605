menu.faqs {
  overflow: overlay;
  /*  */
  padding: var(--em2);
  margin-top: calc(var(--em2) * -1);
  margin-left: calc(var(--em2) * -1);
  /*  */
}
menu.faqs article div.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
menu.faqs article h3 {
  font-weight: var(--fw400);
  width: 87.5%;
}
menu.faqs article h3 i {
  font-weight: var(--fw500);
  font-size: inherit;
}
menu.faqs .chevron {
  height: var(--em3);
  transition: var(--t250);
}
menu.faqs .chevron.rotate {
  transform: rotate(180deg);
}
menu.faqs blockquote {
  display: flex;
  flex-direction: column;
  gap: var(--em2);
  max-height: 0;
  overflow: hidden;
  transition: var(--t250);
}
menu.faqs article hr {
  display: block;
  width: 100%;
}
menu.faqs blockquote p {
  font-size: .875em;
  line-height: var(--em4);
}
menu.faqs blockquote p i {
  font-weight: var(--fw500);
  font-size: inherit;
}
menu.faqs blockquote p b {
  color: var(--red);
  font-size: inherit;
  font-weight: 500;
}
menu.faqs blockquote p strong {
  font-weight: var(--fw600);
}
sup {
  vertical-align: super;
  font-size: smaller;
  line-height: 0;
}

/* MEDIA */
@media screen and (orientation: landscape) {
  menu.faqs article,
  menu.faqs article * {
    cursor: pointer;
    user-select: none;
  }
}
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
  menu.faqs h3 {
    font-size: var(--em3);
  }
}
/* landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
  menu.faqs {
    display: flex;
    flex-direction: column;
    grid-template-columns: none;
    grid-template-rows: none;
  }
  menu.faqs article,
  menu.faqs blockquote {
    gap: var(--em1);
  }
  menu.faqs h3 {
    font-size: var(--em3);
  }
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
  menu.faqs article,
  menu.faqs blockquote {
    gap: var(--em2);
  }
  menu.faqs article {
    padding: var(--em2);
  }
}
/* desktop */
@media screen and (min-width: 1024px) and (max-height: 767px) {
  menu.faqs {
    gap: var(--em6);
  }
  menu.faqs article {
    width: 87.5%;
  }
}
@media screen and (min-width: 1536px) { /* 700 */
  menu.faqs article,
  menu.faqs blockquote {
    gap: var(--em2-3);
  }
}
@media screen and (min-width: 1920px) and (min-height: 929px) {
  .manager menu.faqs {
    height: fit-content;
  }
  menu.faqs {
    gap: var(--em6);
  }
  menu.faqs article {
    width: 75%;
  }
}