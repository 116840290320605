menu article blockquote.loader {
	position: absolute;
	background: transparent;
	background: rgba(255, 255, 255, 0.75);
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0; left: 0;
	border-radius: inherit;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: var(--t375);
}
menu article blockquote.loader::after {
	content: '';
	width: var(--em6);
	height: var(--em6);
	border-radius: 50%;
	border: 3px solid var(--red);
	border-top: 3px solid transparent;
	-webkit-animation: spin 750ms linear infinite; /* Safari */
  animation: spin 750ms linear infinite;
	stroke-dasharray: 1, 200;
}
@-webkit-keyframes spin { /* Safari */
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% { transform: rotate(360deg); }
}
/*  */

menu {
	display: grid;
	gap: var(--em6);
}
menu article {
	position: relative;
	gap: var(--em3);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: var(--white);
	padding: var(--em3);
	border-radius: var(--em1);
	box-shadow: 0 0 var(--em2) var(--dark25);
	transition: var(--t250);
	user-select: none;
}
/* menu article a {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: inherit;
  color: var(--dark);
} */
menu article:hover {
	box-shadow: 0 0 var(--em2) var(--dark50);
}
h3,
h4,
h5 {
	font-weight: var(--fw600);
}

/* declaração contratual */
menu article p i {
	font-size: var(--em2-3);
}
menu article span {
	display: flex;
	align-items: flex-end;
	align-items: center;
	align-self: flex-end;
	gap: var(--em0-1);

}
menu article span p {
	font-size: var(--em2-3);
}
menu article strong {
	font-weight: var(--fw500);
	font-size: var(--em3);
}
menu article span svg {
	height: var(--em2-3);
}
menu article span svg path {
	fill: var(--dark);
}
.relativeSVG {
	position: relative;
}
.absoluteSVG {
	position: absolute;
	border-radius: 0 0 var(--em1) 0;
}
#PDFsvg {
	left: 0;
	bottom: 0;
	transform: scale(-1, 1);
	height: var(--em17);
	z-index: 0;
}
menu article p {
	z-index: 1;
}

menu article figure {
	position: relative;
	/* width: fit-content; */
	display: flex;
	flex-direction: column;
	gap: var(--em3);
}

/* plano  */
.barDiv {
	display: none;
}
menu .plano .section-div {
	display: none;
}
menu article hr {
	border: none;
	border-top: 2px solid var(--red);
	width: 75%;
	margin: 0 auto;
}

.declaracao-short {
	display: none;
}

menu .group {
	display: none;
}

/* latest rec venc */
.PDFinfo {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	gap: var(--em1);
}
.pdf {
	height: var(--em9);
}
.PDFinfo p {
	font-size: var(--em2);
}

menu .newsletter {
	padding: 0;
	gap: 0;
	margin: 0;
	position: relative;
	background: var(--darktransparent);
}
@media screen and (min-height: 360px) {
	menu .newsletter {
		overflow: hidden;
	}
}
menu .newsletter div {
	overflow: hidden;
	border-radius: inherit;
	height: 100%;
}
menu .newsletter img {
	height: 100%;
	width: 100%;
	object-fit: contain;
	object-position: bottom center;
	border-radius: inherit;
	opacity: 1;
	z-index: 0;
	transform: scale(1.01);
	overflow: hidden;
}
menu .newsletter img.BG {
	overflow: hidden;
	position: absolute;
	object-fit: cover;
	object-position: center;
	z-index: -1;
	filter: blur(3px);
	left: 0; top: 0;
}

/* MEDIA QUERIES */
/* mobile */
@media screen and (min-width: 410px) and (min-height: 730px) and (orientation: portrait) {
	menu {
		gap: var(--em7) var(--em11);
	}
	menu h3 {
		font-size: var(--em3);
	}
}

/* tablet */
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
	menu h3 {
		font-size: var(--em3-4);
	}
	menu article span p {
		font-size: var(--em3);
	}
	menu article span svg {
		height: var(--em3);
	}
	menu article p i {
		font-size: var(--em3);
	}
	.pdf {
		height: var(--em9-10);
	}
	.PDFinfo p {
		font-size: var(--em2-3);
	}
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
	menu {
		gap: var(--em4);
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
	}
	.manager body {
		overflow: hidden;
	}
	menu article {
		padding: var(--em1);
		width: 100%;
		gap: 0;
	}
	menu article h3 {
		font-size: var(--em2-3);
	}
	menu .newsletter {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}
	menu .recibo {
		grid-column: 2 / 3;
		grid-row: 3 / 4;
	}
	menu .sinistralidade {
		grid-column: 3 / 4;
		grid-row: 3 / 4;
	}
	menu .declaracao {
		grid-column: 1 / 2;
		grid-row: 3 / 4;
		overflow-y: overlay;
	}
	menu .bloco {
		grid-column: 3 / 4;
		grid-row: 2 / 4;
		overflow-y: overlay;
	}
	.declaracao-long {
		display: none;
	}
	.declaracao-short {
		display: block;
	}
	#BANNERsvg {
		display: none;
	}
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
	.declaracao-long {
		display: block;
	}
	.declaracao-short {
		display: none;
	}
	menu article {
		padding: var(--em2);
	}
	menu article h3 {
		font-size: var(--em3);
	}
	menu #BANNERsvg {
		display: block;
		right: 0;
	}
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
	menu {
		gap: var(--em4);
		grid-template-rows: repeat(3, 1fr);
	}
	menu .declaracao {
		grid-column: 3 / 4;
	}
	menu .newsletter {
		grid-column: 1 / 3;
		grid-row: 1 / 3;
	}
	menu .plano {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}
	menu article {
		padding: var(--em3);
	}
	menu article h3 {
		font-size: var(--em3-4);
	}
	menu .tablet-br {
		display: block;
	}
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
	.declaracao-long {
		display: block;
	}
	.declaracao-short {
		display: none;
	}
	menu article {
		padding: var(--em4);
	}
	menu article p i {
		font-size: var(--em3);
	}
	menu article span p {
		font-size: var(--em3);
	}
	menu article span svg {
		height: var(--em3);
	}
	.pdf {
		height: var(--em11);
	}
	.PDFinfo p {
		font-size: var(--em3);
	}
	menu .tablet-br {
		display: none;
	}
}

/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
	menu article blockquote.loader {
		display: none;
	}
}
@media screen and (min-width: 1280px) and (max-height: 799px) {
	.declaracao-long {
		display: block;
	}
	.declaracao-short {
		display: none;
	}
	menu article {
		padding: var(--em2);
	}
	menu article h3 {
		font-size: var(--em3);
	}
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
	menu {
		grid-template-columns: repeat(4, 1fr);
	}
	menu article {
		padding: var(--em3);
	}
	menu article h3 {
		font-size: var(--em3-4);
	}
	menu article p i {
		font-size: var(--em3);
	}
	.pdf {
		height: var(--em11);
	}
	.PDFinfo p {
		font-size: var(--em2-3);
	}
	menu .plano {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}
	menu .recibo {
		grid-column: 3 / 4;
		grid-row: 2 / 3;
	}
	menu .sinistralidade {
		grid-column: 4 / 5;
		grid-row: 3 / 4;
	}
	menu .declaracao {
		grid-column: 3 / 4;
		grid-row: 3 / 4;
		overflow-y: overlay;
	}
	menu .bloco {
		grid-column: 4 / 5;
		grid-row: 1 / 4;
	}
}
@media screen and (min-width: 1536px) { /* 656 700 */
	menu .tablet-br {
    display: none;
	}
	#PDFsvg {
		height: var(--em18);
	}
	menu {
		grid-template-columns: repeat(4, 1fr);
	}
	menu article {
		padding: var(--em2-3);
	}
	menu article h3 {
		font-size: var(--em3-4);
	}
	menu article span p {
		font-size: var(--em3);
	}
	menu article span svg {
		height: var(--em3);
	}
	.pdf {
		height: var(--em11);
	}
	.PDFinfo p {
		font-size: var(--em2-3);
	}
	menu .plano {
		grid-column: 3 / 4;
		grid-row: 1 / 2;
	}
	menu .recibo {
		grid-column: 3 / 4;
		grid-row: 2 / 3;
	}
	menu .sinistralidade {
		grid-column: 4 / 5;
		grid-row: 3 / 4;
	}
	menu .declaracao {
		grid-column: 3 / 4;
		grid-row: 3 / 4;
		overflow-y: overlay;
	}
	menu .bloco {
		grid-column: 4 / 5;
		grid-row: 1 / 4;
	}
}
@media screen and (min-width: 1920px) { /* 900 929 */
	menu article blockquote.loader {
		display: none;
	}
	#PDFsvg {
		height: var(--em18-19);
	}
	menu {
		gap: var(--em10);
		grid-template-rows: repeat(3, 1fr);
	}
	menu article h3 {
		font-size: var(--em4);
	}
	menu article span p {
		font-size: var(--em3-4);
	}
	menu article span svg {
		height: var(--em3-4);
	}
}
