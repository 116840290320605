menu .dashboard {
  display: none;
}
menu.perfil .dashboard {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%; height: 100%;
  top: 0; left: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999999999;
  opacity: 1;
  /* pointer-events: auto;
  user-select: auto; */
  transition: var(--t375);
  cursor: alias;
}
menu .dashboard * {
  cursor: default;
}
menu.perfil .dashboard.fade {
  opacity: 0;
  pointer-events: none;
  user-select: none;
  transition-delay: 250ms;
}
menu.perfil .dashboard ul {
  position: relative;
  background: var(--white);
  padding: var(--em3) var(--em4);
  border-radius: var(--em1-2);
  transform: scale(1);
  transition: var(--t250);
  transition-delay: 250ms;
  /* grid */
  display: grid;
  grid-template-columns: .125fr .875fr;
  grid-template-rows: auto;
  gap: var(--embr) var(--em3);
  gap: var(--em0) var(--em3);
}
menu.perfil .dashboard.fade ul {
  transform: scale(0);
  transition-delay: 0ms;
}
.dashboard li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--em5);
}
.dashboard li.ellipse {
  justify-content: center;
}
.dashboard svg {
  height: var(--em1-2);
}
.dashboard svg.star {
  height: var(--em4);
}
.star-container {
  margin-bottom: var(--em4);
  gap: var(--em0-1);
}
.last-star .star-container {
  margin: 0;
}
/* .dashboard li:last-of-type .star-container {
  margin-bottom: var(--em2);
} */

.dashboard h4,
.dashboard h6 {
  white-space: nowrap;
  cursor: default;
  font-size: var(--em3);
}
.dashboard h6.selected {
  background: var(--dark);
  color: var(--white);
  padding: var(--embr) var(--em0-1);
  border-radius: var(--em0-1);
}
.line-container {
  justify-content: center;
}
.dashboard .line {
  width: 2px;
  height: 100%;
  background: var(--red);
}
.dashboard hr {
  grid-column: span 2;
  width: 87.5%;
  width: 100%;
  border: none;
  border-top: 2px solid var(--red);
  margin: var(--em0-1) auto;
}
.dashboard .dashboard-txt {
  grid-column: span 2;
  justify-content: center;
}
.dashboard .dashboard-txt p {
  font-weight: var(--fw500);
  white-space: nowrap;
}
.dashboard .dashboard-txt h6 {
  font-weight: var(--fw500);
  font-size: var(--em3);
  text-align: center;
  padding: var(--embr) var(--em0);
  border-radius: var(--em0-1);
  background: var(--red);
  color: var(--white);
}
/* .last-star {
  margin-bottom: var(--em1);
} */

/* media */
@media screen and (min-width: 375px) and (min-height: 667px) {
  .dashboard hr {
    margin: var(--em1) auto;
  }
  .dashboard h6 {
    font-size: var(--em3-4);
  }
}