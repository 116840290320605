menu.perfil article figure {
  align-items: center;
}
h5 {
  color: var(--red);
}
menu ul {
  display: flex;
  flex-direction: column;
  gap: var(--em2);
}
menu ul li {
  display: flex;
  flex-direction: column;
  gap: var(--embr);
}
menu.perfil article.info,
menu.perfil article.info-c {
  height: 50vh;
  overflow-y: overlay;
  justify-content: flex-start;
}
menu.perfil article.info-c {
  height: 25vh;
}
menu li div {
  display: flex;
  gap: var(--em6);
}
h5,
menu article div p,
menu li p,
menu li div label {
  font-size: var(--em2-3);
}
menu li div label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
menu li div label span {
  margin-right: var(--em1);
}
menu li div label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  pointer-events: none;
}
.check {
  background: var(--dark25);
  width: var(--em2); height: var(--em2);
  border-radius: 50%;
  outline: 2px solid var(--dark25);
  align-self: center;
}
menu li div label input:checked + .check {
  background: var(--red);
}
menu.perfil article {
  position: relative;
}
menu.perfil #cone {
  height: var(--em8);
  align-self: center;
}
@media screen and (orientation: landscape) {
  menu.perfil #cone {
    position: absolute;
    bottom: 50%;
    transform: translateY(75%);
  }
}

/* MEDIA QUERIES */
/* tablet */
@media screen and (min-width: 480px) and (min-height: 800px) and (orientation: portrait) {
  h5,
  menu article div p,
  menu li p,
  menu li div label {
    font-size: var(--em3);
  }
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
  menu.perfil article.info {
    height: auto;
    gap: var(--em3);
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  menu.perfil article.info-c {
    height: auto;
    gap: var(--em3);
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
  menu.perfil .plano {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
  }
  menu.perfil article div.props.flex {
    justify-content: center;
  }
  menu.perfil .declaracao {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
  }
  menu.perfil .cv {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
  menu.perfil .info ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  menu.perfil #cone {
    height: var(--em10);
  }
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
  menu.perfil .tablet-br {
    display: none;
  }
  menu.perfil #cone {
    height: var(--em11);
  }
}
@media screen and (min-width: 1360px) and (min-height: 912px) and (orientation: landscape) {
  menu.perfil #cone {
    height: var(--em12);
  }
}

/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
  menu.perfil #cone {
    height: var(--em11);
  }
}
@media screen and (min-width: 1280px) and (max-height: 799px) {
  menu.perfil .info ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  /* menu.perfil article hr {
    width: 87.5%;
  } */
  menu .rank svg {
    height: var(--em5);
  }
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
  menu.perfil article.info-c {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  }
  menu.perfil .cv {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
  }
  menu article div p,
  h5,
  menu li p,
  menu li div label {
    font-size: var(--em3);
  }
  menu.perfil #cone {
    height: var(--em11-12);
  }
  menu.perfil .rank {
    margin-top: var(--em3);
  }
  menu .rank svg {
    height: var(--em5);
  }
}
@media screen and (min-width: 1536px) { /* 700 */
  menu.perfil article.info-c {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  }
  menu.perfil .cv {
    grid-column: 4 / 5;
    grid-row: 3 / 4;
  }
  menu article div p,
  h5,
  menu li p,
  menu li div label {
    font-size: var(--em3);
  }
  menu.perfil #cone {
    height: var(--em11-12);
  }
  menu.perfil .rank {
    margin-top: var(--em1);
  }
  menu.perfil .rank svg {
    height: var(--em5);
  }
}
@media screen and (min-width: 1920px) and (min-height: 929px) {
  menu.perfil #cone {
    height: var(--em12);
  }
  menu .rank svg,
  menu.perfil .rank svg {
    height: var(--em5-6);
  }
}