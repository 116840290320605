#BANNERsvg {
  top: 0;
  right: var(--em0);
  height: var(--em12);
}
menu.perfil .group {
  display: flex;
  flex-direction: column;
  gap: inherit;
}
.tablet-br,
menu.perfil .plano .span-link,
menu .historico,
menu .plano-p,
menu .plano-hr,
menu .group-hr {
  display: none;
}
menu.perfil .plano-p,
menu.perfil .plano-hr {
  display: block;
}
menu.perfil button.historico {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--em2);
  padding: var(--em0) var(--em2);
  font-weight: var(--fw500);
  width: fit-content;
  border: 2px solid var(--red);
  border-radius: var(--em1-2);
  transition: var(--t250);
  color: var(--red);
  background: transparent;
}
menu.perfil button.historico:hover {
  color: var(--white);
  background: var(--red);
  cursor: pointer;
}
menu.perfil button.historico:hover svg path {
  fill: var(--white);
}
menu.perfil .historico svg {
  height: var(--em4);
}
menu.perfil .historico svg path {
  fill: var(--red);
  transition: var(--t250);
}
menu.perfil button.historico,
menu.perfil .plano p {
  font-size: var(--em2-3);
  width: fit-content;
  margin: 0 auto;
}
menu.perfil .rank {
  margin: var(--em1) 0;
}

/* MEDIA QUERIES */
/* mobile */
@media screen and (min-width: 410px) and (min-height: 730px) and (orientation: portrait) {
  #BANNERsvg {
    top: 0;
    right: var(--em0);
    height: var(--em12-13);
  }
}
@media screen and (min-width: 600px) and (min-height: 960px) and (orientation: portrait) {
  menu.perfil .plano .plano-p,
  menu.perfil button.historico {
    font-size: var(--em3);
  }
  menu.perfil .historico svg {
    height: var(--em4-5);
  }
}
@media screen and (min-width: 768px) and (min-height: 954px) and (orientation: portrait) {
  #BANNERsvg {
    height: var(--em13);
  }
  menu .group-hr {
    display: block;
  }
  menu.perfil article hr {
    width: auto;
    height: 100%;
    border: none;
    border-right: 2px solid var(--red);
    margin: 0;
  }
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: var(--em1) 0;
  }
  menu.perfil .group {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

/* tablet landscape */
@media screen and (min-width: 800px) and (min-height: 480px) and (orientation: landscape) {
  menu.perfil .plano {
    gap: var(--em3);
  }
  menu.perfil .group {
    flex-direction: row;
  }
  menu.perfil article hr {
    width: auto;
    height: 100%;
    border: none;
    border-right: 2px solid var(--red);
    margin: 0;
  }
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: 0;
    font-size: var(--em2);
  }
  menu.perfil .historico svg {
    height: var(--em3-4);
  }
  menu.perfil #BANNERsvg {
    display: block;
  }
  menu.perfil .rank {
    margin: 0;
  }
  menu.perfil .group {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 960px) and (min-height: 600px) and (orientation: landscape) {
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: 0;
    font-size: var(--em2-3);
  }
}
@media screen and (min-width: 1024px) and (min-height: 698px) and (orientation: landscape) {
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: var(--em1) 0;
    font-size: var(--em3);
  }
  menu.perfil .rank svg {
    height: var(--em5);
  }
  menu #BANNERsvg {
    height: var(--em12-13);
    right: var(--em1);
  }
  menu.perfil .historico svg {
    height: var(--em4);
  }
  menu.perfil .rank {
    gap: var(--em1-2);
  }
}
@media screen and (min-width: 1280px) and (min-height: 800px) and (orientation: landscape) {
  menu.perfil .rank svg {
    height: var(--em5-6);
  }
  menu #BANNERsvg {
    height: var(--em13);
    right: var(--em3);
  }
  menu.perfil .historico svg {
    height: var(--em4-5);
  }
}
@media screen and (min-width: 1360px) and (min-height: 912px) and (orientation: landscape) {
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: var(--em2) 0;
  }
}

/* landscape */
@media screen and (min-width: 1024px) and (max-height: 767px) {
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: var(--em0-1) 0;
  }
}
@media screen and (min-width: 1280px) and (max-height: 799px) {
  menu #BANNERsvg {
    display: block;
    height: var(--em12-13);
  }
  menu.perfil .group {
    gap: var(--em5);
  }
}
@media screen and (min-width: 1400px) and (min-height: 749px) {
  menu #BANNERsvg {
    height: var(--em13);
    right: var(--em2);
  }
  .tablet-br {
    display: block;
  }
  menu.perfil .tablet-br,
  menu.perfil .land-br {
    display: none;
  }
  menu.perfil button.historico,
  menu.perfil .plano p {
    margin: var(--em1-2) 0;
  }
  menu.perfil .group {
    gap: var(--em4);
  }
}
@media screen and (min-width: 1536px) { /* 700 */
  menu.perfil button.historico,
  menu.perfil .plano p {
    font-size: var(--em3);
  }
  menu.perfil #BANNERsvg {
    height: var(--em13);
    right: var(--em2);
  }
  /* .tablet-br {
    display: block;
  } */
  menu.perfil .tablet-br,
  menu.perfil .land-br {
    display: none;
  }
  menu.perfil button.historico {
    padding: var(--em0-1) var(--em2);
  }
  menu.perfil .historico svg {
    height: var(--em4-5);
  }
  .dashboard figure {
    padding: var(--em1-2) var(--em2);
  }
  .dashboard figure svg {
    height: var(--em5-6);
  }
}
@media screen and (min-width: 1920px) { /* 929 */
  menu #BANNERsvg {
    right: var(--em1);
    height: var(--em13-14);
  }
  menu.perfil #BANNERsvg {
    right: var(--em3);
    height: var(--em14);
  }
}